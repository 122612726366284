import React, { useEffect, useState } from "react";

import { Body, Caption, Heading1, Hyperlink } from "../../styles";

const Blocks: React.FC = () => {
  const [displayText, setDisplayText] = useState<string>("");

  useEffect(() => {
    const timers: NodeJS.Timeout[] = [];

    for (let i = 0; i < KEYPRESS_DATA.length; i++) {
      const keypress = KEYPRESS_DATA[i];
      setDisplayText("");

      const timer = setTimeout(
        () => {
          if (keypress.type === "keydown") {
            const key = KEYBINDS[keypress.data.key];
            // const key = keypress.data.key

            setDisplayText((prevText: string) => prevText + " " + key);
            // setDisplayText(key);
          }
        },
        ((keypress.frame + keypress.data.subframe) * 1000) / 60
      );

      timers.push(timer);
    }

    // Clean up timers when the component unmounts
    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, []);

  return (
    <>
      <Heading1>Fast blocks</Heading1>
      <Body>
        Keystrokes for the 59th fastest tetris player in the world. Follow along
        ;)
      </Body>
      <br />
      <Body>40 lines cleared</Body>
      <Body>18.943 seconds</Body>
      <Body>5.33 pieces per second</Body>
      <Body>306 keys pressed</Body>
      <br />
      <Hyperlink href="https://tetr.io/#R:BVKu0jX_i" target="_blank">
        Check out the full replay
      </Hyperlink>
      <Body>{displayText}</Body>
    </>
  );
};

export default Blocks;

type Keypress = {
  frame: number;
  type: string;
  data: {
    key: string;
    hoisted?: boolean;
    subframe: number;
  };
};

const KEYBINDS: Record<string, string> = {
  moveLeft: "left",
  moveRight: "right",
  rotateCW: "up",
  softDrop: "down",
  hardDrop: "space",
  hold: "c",
  rotateCCW: "x",
  rotate180: "z"
};

const KEYPRESS_DATA: Keypress[] = [
  {
    frame: 0,
    type: "keydown",
    data: { key: "hold", hoisted: true, subframe: 0 }
  },
  {
    frame: 0,
    type: "keydown",
    data: { key: "moveLeft", hoisted: true, subframe: 0 }
  },
  { frame: 3, type: "keyup", data: { key: "hold", subframe: 0.6 } },
  { frame: 4, type: "keyup", data: { key: "moveLeft", subframe: 0.9 } },
  { frame: 6, type: "keydown", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 8, type: "keydown", data: { key: "rotateCCW", subframe: 0 } },
  { frame: 8, type: "keydown", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 10, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 11, type: "keyup", data: { key: "moveLeft", subframe: 0.9 } },
  { frame: 13, type: "keyup", data: { key: "rotateCCW", subframe: 0.7 } },
  { frame: 13, type: "keydown", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 16, type: "keydown", data: { key: "moveRight", subframe: 0.1 } },
  { frame: 20, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 23, type: "keyup", data: { key: "moveRight", subframe: 0 } },
  { frame: 23, type: "keydown", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 25, type: "keydown", data: { key: "hold", subframe: 0.8 } },
  { frame: 27, type: "keyup", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 28, type: "keydown", data: { key: "rotateCW", subframe: 0 } },
  { frame: 30, type: "keyup", data: { key: "rotateCW", subframe: 0 } },
  { frame: 32, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 32, type: "keydown", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 34, type: "keydown", data: { key: "moveRight", subframe: 0.1 } },
  { frame: 39, type: "keyup", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 41, type: "keydown", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 45, type: "keyup", data: { key: "moveRight", subframe: 0.4 } },
  { frame: 45, type: "keydown", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 46, type: "keyup", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 47, type: "keydown", data: { key: "hold", subframe: 0.7 } },
  { frame: 49, type: "keydown", data: { key: "moveRight", subframe: 0.5 } },
  { frame: 50, type: "keydown", data: { key: "rotate180", subframe: 0.1 } },
  { frame: 52, type: "keyup", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 52, type: "keyup", data: { key: "hold", subframe: 0.5 } },
  { frame: 57, type: "keyup", data: { key: "moveRight", subframe: 0.4 } },
  { frame: 57, type: "keyup", data: { key: "rotate180", subframe: 0.4 } },
  { frame: 57, type: "keydown", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 59, type: "keydown", data: { key: "hold", subframe: 0.2 } },
  { frame: 59, type: "keydown", data: { key: "moveLeft", subframe: 0.8 } },
  { frame: 60, type: "keydown", data: { key: "rotateCCW", subframe: 0.5 } },
  { frame: 62, type: "keyup", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 63, type: "keyup", data: { key: "hold", subframe: 0.6 } },
  { frame: 65, type: "keyup", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 66, type: "keydown", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 69, type: "keydown", data: { key: "hold", subframe: 0.4 } },
  { frame: 70, type: "keydown", data: { key: "rotateCCW", subframe: 0.5 } },
  { frame: 71, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 71, type: "keyup", data: { key: "hold", subframe: 0.6 } },
  { frame: 74, type: "keyup", data: { key: "moveLeft", subframe: 0.1 } },
  { frame: 74, type: "keydown", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 76, type: "keyup", data: { key: "rotateCCW", subframe: 0.9 } },
  { frame: 78, type: "keydown", data: { key: "moveRight", subframe: 0.6 } },
  { frame: 83, type: "keyup", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 86, type: "keyup", data: { key: "moveRight", subframe: 0.2 } },
  { frame: 87, type: "keydown", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 89, type: "keydown", data: { key: "moveLeft", subframe: 0.2 } },
  { frame: 91, type: "keydown", data: { key: "rotateCW", subframe: 0.3 } },
  { frame: 93, type: "keyup", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 97, type: "keyup", data: { key: "rotateCW", subframe: 0 } },
  { frame: 98, type: "keyup", data: { key: "moveLeft", subframe: 0.2 } },
  { frame: 98, type: "keydown", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 100, type: "keydown", data: { key: "rotateCCW", subframe: 0.7 } },
  { frame: 101, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 105, type: "keydown", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 106, type: "keyup", data: { key: "rotateCCW", subframe: 0.3 } },
  { frame: 110, type: "keydown", data: { key: "moveRight", subframe: 0 } },
  { frame: 111, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 115, type: "keydown", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 118, type: "keydown", data: { key: "hold", subframe: 0.9 } },
  { frame: 121, type: "keydown", data: { key: "rotate180", subframe: 0 } },
  { frame: 121, type: "keyup", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 122, type: "keyup", data: { key: "hold", subframe: 0.7 } },
  { frame: 125, type: "keyup", data: { key: "rotate180", subframe: 0.9 } },
  { frame: 125, type: "keydown", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 126, type: "keyup", data: { key: "moveRight", subframe: 0.5 } },
  { frame: 129, type: "keydown", data: { key: "hold", subframe: 0.1 } },
  { frame: 131, type: "keyup", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 131, type: "keydown", data: { key: "rotateCCW", subframe: 0.6 } },
  { frame: 131, type: "keydown", data: { key: "moveLeft", subframe: 0.7 } },
  { frame: 134, type: "keyup", data: { key: "hold", subframe: 0.4 } },
  { frame: 134, type: "keyup", data: { key: "rotateCCW", subframe: 0.4 } },
  { frame: 134, type: "keyup", data: { key: "moveLeft", subframe: 0.4 } },
  { frame: 135, type: "keydown", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 138, type: "keydown", data: { key: "rotateCCW", subframe: 0.5 } },
  { frame: 138, type: "keydown", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 142, type: "keyup", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 145, type: "keyup", data: { key: "moveLeft", subframe: 0 } },
  { frame: 146, type: "keyup", data: { key: "rotateCCW", subframe: 0.6 } },
  { frame: 146, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 150, type: "keydown", data: { key: "moveRight", subframe: 0 } },
  { frame: 153, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 158, type: "keyup", data: { key: "moveRight", subframe: 0.1 } },
  { frame: 158, type: "keydown", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 159, type: "keydown", data: { key: "hold", subframe: 0.9 } },
  { frame: 161, type: "keyup", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 163, type: "keyup", data: { key: "hold", subframe: 0.8 } },
  { frame: 163, type: "keydown", data: { key: "rotateCW", subframe: 0.8 } },
  { frame: 166, type: "keyup", data: { key: "rotateCW", subframe: 0.3 } },
  { frame: 166, type: "keydown", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 169, type: "keydown", data: { key: "hold", subframe: 0.3 } },
  { frame: 169, type: "keyup", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 170, type: "keydown", data: { key: "rotateCW", subframe: 0.6 } },
  { frame: 173, type: "keyup", data: { key: "rotateCW", subframe: 0.9 } },
  { frame: 175, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 175, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 178, type: "keydown", data: { key: "hold", subframe: 0.7 } },
  { frame: 180, type: "keydown", data: { key: "rotateCCW", subframe: 0.2 } },
  { frame: 180, type: "keydown", data: { key: "moveLeft", subframe: 0.2 } },
  { frame: 181, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 182, type: "keyup", data: { key: "moveLeft", subframe: 0.5 } },
  { frame: 184, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 187, type: "keyup", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 187, type: "keydown", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 190, type: "keydown", data: { key: "hold", subframe: 0 } },
  { frame: 190, type: "keydown", data: { key: "moveRight", subframe: 0 } },
  { frame: 195, type: "keyup", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 197, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 199, type: "keyup", data: { key: "moveRight", subframe: 0.7 } },
  { frame: 201, type: "keydown", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 203, type: "keydown", data: { key: "hold", subframe: 0.3 } },
  { frame: 206, type: "keydown", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 206, type: "keydown", data: { key: "moveLeft", subframe: 0.1 } },
  { frame: 206, type: "keyup", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 209, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 209, type: "keyup", data: { key: "moveLeft", subframe: 0 } },
  { frame: 211, type: "keyup", data: { key: "rotateCCW", subframe: 0.5 } },
  { frame: 211, type: "keydown", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 219, type: "keydown", data: { key: "rotateCCW", subframe: 0.2 } },
  { frame: 219, type: "keydown", data: { key: "moveLeft", subframe: 0.2 } },
  { frame: 221, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 221, type: "keyup", data: { key: "moveLeft", subframe: 0.2 } },
  { frame: 227, type: "keyup", data: { key: "rotateCCW", subframe: 0.9 } },
  { frame: 228, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 231, type: "keydown", data: { key: "moveRight", subframe: 0 } },
  { frame: 232, type: "keydown", data: { key: "rotate180", subframe: 0.5 } },
  { frame: 236, type: "keyup", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 240, type: "keyup", data: { key: "moveRight", subframe: 0.2 } },
  { frame: 240, type: "keyup", data: { key: "rotate180", subframe: 0.2 } },
  { frame: 242, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 245, type: "keydown", data: { key: "rotateCW", subframe: 0.4 } },
  { frame: 246, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 248, type: "keyup", data: { key: "rotateCW", subframe: 0.7 } },
  { frame: 250, type: "keydown", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 253, type: "keydown", data: { key: "hold", subframe: 0.2 } },
  { frame: 254, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 255, type: "keyup", data: { key: "hold", subframe: 0.8 } },
  { frame: 259, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 262, type: "keydown", data: { key: "rotateCCW", subframe: 0 } },
  { frame: 262, type: "keydown", data: { key: "moveLeft", subframe: 0 } },
  { frame: 265, type: "keyup", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 268, type: "keyup", data: { key: "rotateCCW", subframe: 0.9 } },
  { frame: 270, type: "keydown", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 272, type: "keydown", data: { key: "hold", subframe: 0.5 } },
  { frame: 276, type: "keyup", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 276, type: "keydown", data: { key: "rotate180", subframe: 0.7 } },
  { frame: 276, type: "keydown", data: { key: "moveRight", subframe: 0.7 } },
  { frame: 277, type: "keyup", data: { key: "hold", subframe: 0.1 } },
  { frame: 279, type: "keyup", data: { key: "moveLeft", subframe: 0 } },
  { frame: 279, type: "keyup", data: { key: "moveRight", subframe: 0.7 } },
  { frame: 281, type: "keydown", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 282, type: "keyup", data: { key: "rotate180", subframe: 0.1 } },
  { frame: 283, type: "keydown", data: { key: "hold", subframe: 0.5 } },
  { frame: 286, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 286, type: "keydown", data: { key: "rotateCW", subframe: 0 } },
  { frame: 286, type: "keydown", data: { key: "moveRight", subframe: 0 } },
  { frame: 287, type: "keyup", data: { key: "rotateCW", subframe: 0.1 } },
  { frame: 288, type: "keyup", data: { key: "moveRight", subframe: 0 } },
  { frame: 289, type: "keyup", data: { key: "hold", subframe: 0.2 } },
  { frame: 291, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 292, type: "keydown", data: { key: "hold", subframe: 0.8 } },
  { frame: 294, type: "keydown", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 295, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 296, type: "keydown", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 298, type: "keyup", data: { key: "moveLeft", subframe: 0.2 } },
  { frame: 299, type: "keyup", data: { key: "hold", subframe: 0.2 } },
  { frame: 300, type: "keydown", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 302, type: "keyup", data: { key: "rotateCCW", subframe: 0.4 } },
  { frame: 303, type: "keydown", data: { key: "moveRight", subframe: 0.6 } },
  { frame: 306, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 310, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 313, type: "keyup", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 318, type: "keydown", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 321, type: "keydown", data: { key: "hold", subframe: 0.3 } },
  { frame: 323, type: "keydown", data: { key: "rotate180", subframe: 0.8 } },
  { frame: 325, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 325, type: "keyup", data: { key: "hold", subframe: 0.1 } },
  { frame: 326, type: "keyup", data: { key: "moveRight", subframe: 0.9 } },
  { frame: 328, type: "keydown", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 330, type: "keyup", data: { key: "rotate180", subframe: 0.1 } },
  { frame: 332, type: "keydown", data: { key: "moveLeft", subframe: 0.4 } },
  { frame: 335, type: "keydown", data: { key: "rotateCW", subframe: 0.1 } },
  { frame: 337, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 339, type: "keyup", data: { key: "moveLeft", subframe: 0.5 } },
  { frame: 340, type: "keyup", data: { key: "rotateCW", subframe: 0.2 } },
  { frame: 344, type: "keydown", data: { key: "softDrop", subframe: 0 } },
  { frame: 347, type: "keydown", data: { key: "rotateCCW", subframe: 0 } },
  { frame: 348, type: "keyup", data: { key: "softDrop", subframe: 0.5 } },
  { frame: 350, type: "keyup", data: { key: "rotateCCW", subframe: 0.4 } },
  { frame: 350, type: "keydown", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 351, type: "keydown", data: { key: "moveLeft", subframe: 0.1 } },
  { frame: 351, type: "keydown", data: { key: "hold", subframe: 0.8 } },
  { frame: 355, type: "keydown", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 357, type: "keyup", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 357, type: "keyup", data: { key: "hold", subframe: 0.4 } },
  { frame: 360, type: "keyup", data: { key: "moveLeft", subframe: 0.9 } },
  { frame: 360, type: "keydown", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 362, type: "keyup", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 366, type: "keydown", data: { key: "rotateCW", subframe: 0.1 } },
  { frame: 366, type: "keydown", data: { key: "moveRight", subframe: 0.1 } },
  { frame: 368, type: "keyup", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 368, type: "keyup", data: { key: "rotateCW", subframe: 0.6 } },
  { frame: 368, type: "keyup", data: { key: "moveRight", subframe: 0.6 } },
  { frame: 372, type: "keydown", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 374, type: "keydown", data: { key: "moveLeft", subframe: 0.4 } },
  { frame: 375, type: "keydown", data: { key: "rotateCCW", subframe: 0.3 } },
  { frame: 377, type: "keyup", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 382, type: "keyup", data: { key: "moveLeft", subframe: 0.7 } },
  { frame: 382, type: "keyup", data: { key: "rotateCCW", subframe: 0.7 } },
  { frame: 382, type: "keydown", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 387, type: "keydown", data: { key: "moveRight", subframe: 0.4 } },
  { frame: 390, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 390, type: "keydown", data: { key: "rotateCCW", subframe: 0 } },
  { frame: 390, type: "keyup", data: { key: "moveRight", subframe: 0.7 } },
  { frame: 395, type: "keyup", data: { key: "rotateCCW", subframe: 0.9 } },
  { frame: 395, type: "keydown", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 398, type: "keydown", data: { key: "hold", subframe: 0.8 } },
  { frame: 400, type: "keydown", data: { key: "rotateCCW", subframe: 0.2 } },
  { frame: 401, type: "keyup", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 403, type: "keyup", data: { key: "hold", subframe: 0.3 } },
  { frame: 405, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 406, type: "keyup", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 408, type: "keydown", data: { key: "hold", subframe: 0.2 } },
  { frame: 410, type: "keydown", data: { key: "rotateCCW", subframe: 0.7 } },
  { frame: 412, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 412, type: "keydown", data: { key: "moveRight", subframe: 0.2 } },
  { frame: 413, type: "keyup", data: { key: "hold", subframe: 0.3 } },
  { frame: 415, type: "keyup", data: { key: "moveRight", subframe: 0.2 } },
  { frame: 416, type: "keyup", data: { key: "rotateCCW", subframe: 0.6 } },
  { frame: 416, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 418, type: "keydown", data: { key: "hold", subframe: 0.4 } },
  { frame: 422, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 422, type: "keydown", data: { key: "rotateCCW", subframe: 0.2 } },
  { frame: 422, type: "keydown", data: { key: "moveLeft", subframe: 0.2 } },
  { frame: 424, type: "keyup", data: { key: "hold", subframe: 0.6 } },
  { frame: 424, type: "keyup", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 426, type: "keyup", data: { key: "rotateCCW", subframe: 0.5 } },
  { frame: 427, type: "keydown", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 429, type: "keydown", data: { key: "rotateCCW", subframe: 0.6 } },
  { frame: 430, type: "keydown", data: { key: "moveLeft", subframe: 0.5 } },
  { frame: 432, type: "keyup", data: { key: "moveLeft", subframe: 0.4 } },
  { frame: 433, type: "keyup", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 435, type: "keydown", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 436, type: "keyup", data: { key: "rotateCCW", subframe: 0.6 } },
  { frame: 438, type: "keydown", data: { key: "moveLeft", subframe: 0.4 } },
  { frame: 441, type: "keydown", data: { key: "rotateCW", subframe: 0.7 } },
  { frame: 444, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 447, type: "keyup", data: { key: "rotateCW", subframe: 0.3 } },
  { frame: 448, type: "keyup", data: { key: "moveLeft", subframe: 0 } },
  { frame: 448, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 453, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 457, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 459, type: "keydown", data: { key: "rotateCCW", subframe: 0.6 } },
  { frame: 459, type: "keydown", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 463, type: "keyup", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 467, type: "keyup", data: { key: "moveLeft", subframe: 0.4 } },
  { frame: 468, type: "keyup", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 468, type: "keydown", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 471, type: "keydown", data: { key: "moveRight", subframe: 0.4 } },
  { frame: 473, type: "keyup", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 477, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 479, type: "keydown", data: { key: "hold", subframe: 0.6 } },
  { frame: 482, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 482, type: "keydown", data: { key: "moveLeft", subframe: 0.7 } },
  { frame: 483, type: "keyup", data: { key: "hold", subframe: 0.8 } },
  { frame: 485, type: "keyup", data: { key: "moveRight", subframe: 0.3 } },
  { frame: 486, type: "keyup", data: { key: "moveLeft", subframe: 0.1 } },
  { frame: 487, type: "keydown", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 488, type: "keydown", data: { key: "hold", subframe: 0.4 } },
  { frame: 491, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 491, type: "keydown", data: { key: "rotateCCW", subframe: 0 } },
  { frame: 491, type: "keydown", data: { key: "moveLeft", subframe: 0 } },
  { frame: 494, type: "keyup", data: { key: "hold", subframe: 0.7 } },
  { frame: 494, type: "keyup", data: { key: "moveLeft", subframe: 0.7 } },
  { frame: 495, type: "keydown", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 497, type: "keyup", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 499, type: "keydown", data: { key: "moveRight", subframe: 0.1 } },
  { frame: 501, type: "keydown", data: { key: "rotate180", subframe: 0 } },
  { frame: 504, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 507, type: "keyup", data: { key: "moveRight", subframe: 0.3 } },
  { frame: 507, type: "keyup", data: { key: "rotate180", subframe: 0.3 } },
  { frame: 508, type: "keydown", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 511, type: "keydown", data: { key: "rotateCCW", subframe: 0.7 } },
  { frame: 511, type: "keydown", data: { key: "moveLeft", subframe: 0.8 } },
  { frame: 513, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 514, type: "keyup", data: { key: "moveLeft", subframe: 0.8 } },
  { frame: 518, type: "keyup", data: { key: "rotateCCW", subframe: 0 } },
  { frame: 518, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 519, type: "keydown", data: { key: "hold", subframe: 0.2 } },
  { frame: 521, type: "keyup", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 521, type: "keydown", data: { key: "rotateCW", subframe: 0.8 } },
  { frame: 521, type: "keydown", data: { key: "moveRight", subframe: 0.8 } },
  { frame: 524, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 524, type: "keyup", data: { key: "rotateCW", subframe: 0 } },
  { frame: 524, type: "keyup", data: { key: "moveRight", subframe: 0 } },
  { frame: 526, type: "keydown", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 529, type: "keydown", data: { key: "hold", subframe: 0.2 } },
  { frame: 530, type: "keydown", data: { key: "rotateCW", subframe: 0.4 } },
  { frame: 530, type: "keydown", data: { key: "moveRight", subframe: 0.4 } },
  { frame: 531, type: "keyup", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 533, type: "keyup", data: { key: "rotateCW", subframe: 0.1 } },
  { frame: 533, type: "keyup", data: { key: "moveRight", subframe: 0.1 } },
  { frame: 535, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 536, type: "keydown", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 539, type: "keydown", data: { key: "rotateCCW", subframe: 0.5 } },
  { frame: 539, type: "keydown", data: { key: "moveLeft", subframe: 0.5 } },
  { frame: 542, type: "keyup", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 547, type: "keyup", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 547, type: "keydown", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 550, type: "keydown", data: { key: "hold", subframe: 0 } },
  { frame: 552, type: "keydown", data: { key: "rotate180", subframe: 0 } },
  { frame: 554, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 554, type: "keyup", data: { key: "hold", subframe: 0.2 } },
  { frame: 554, type: "keydown", data: { key: "moveRight", subframe: 0.3 } },
  { frame: 557, type: "keyup", data: { key: "moveLeft", subframe: 0.8 } },
  { frame: 557, type: "keyup", data: { key: "moveRight", subframe: 0.8 } },
  { frame: 559, type: "keyup", data: { key: "rotate180", subframe: 0 } },
  { frame: 559, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 563, type: "keydown", data: { key: "moveRight", subframe: 0.2 } },
  { frame: 565, type: "keyup", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 571, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 573, type: "keydown", data: { key: "hold", subframe: 0.2 } },
  { frame: 575, type: "keyup", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 577, type: "keyup", data: { key: "hold", subframe: 0.8 } },
  { frame: 580, type: "keydown", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 582, type: "keydown", data: { key: "hold", subframe: 0.2 } },
  { frame: 584, type: "keydown", data: { key: "rotate180", subframe: 0.7 } },
  { frame: 586, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 587, type: "keyup", data: { key: "hold", subframe: 0.1 } },
  { frame: 589, type: "keyup", data: { key: "moveRight", subframe: 0.9 } },
  { frame: 591, type: "keyup", data: { key: "rotate180", subframe: 0.1 } },
  { frame: 591, type: "keydown", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 596, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 596, type: "keydown", data: { key: "rotateCW", subframe: 0.2 } },
  { frame: 599, type: "keyup", data: { key: "rotateCW", subframe: 0.1 } },
  { frame: 599, type: "keydown", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 601, type: "keydown", data: { key: "hold", subframe: 0 } },
  { frame: 603, type: "keyup", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 604, type: "keydown", data: { key: "rotateCW", subframe: 0.9 } },
  { frame: 605, type: "keyup", data: { key: "hold", subframe: 0.5 } },
  { frame: 607, type: "keyup", data: { key: "rotateCW", subframe: 0.7 } },
  { frame: 608, type: "keydown", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 610, type: "keydown", data: { key: "hold", subframe: 0.5 } },
  { frame: 613, type: "keyup", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 613, type: "keydown", data: { key: "rotateCW", subframe: 0.1 } },
  { frame: 615, type: "keyup", data: { key: "hold", subframe: 0.6 } },
  { frame: 616, type: "keyup", data: { key: "rotateCW", subframe: 0.4 } },
  { frame: 618, type: "keydown", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 620, type: "keydown", data: { key: "hold", subframe: 0 } },
  { frame: 621, type: "keyup", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 625, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 626, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 630, type: "keydown", data: { key: "rotateCCW", subframe: 0 } },
  { frame: 631, type: "keydown", data: { key: "moveLeft", subframe: 0.9 } },
  { frame: 634, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 634, type: "keyup", data: { key: "moveLeft", subframe: 0.8 } },
  { frame: 637, type: "keyup", data: { key: "rotateCCW", subframe: 0.2 } },
  { frame: 637, type: "keydown", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 640, type: "keydown", data: { key: "moveLeft", subframe: 0.8 } },
  { frame: 643, type: "keydown", data: { key: "rotateCW", subframe: 0.4 } },
  { frame: 645, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 647, type: "keyup", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 649, type: "keyup", data: { key: "rotateCW", subframe: 0.1 } },
  { frame: 652, type: "keydown", data: { key: "softDrop", subframe: 0.7 } },
  { frame: 655, type: "keydown", data: { key: "rotateCCW", subframe: 0 } },
  {
    frame: 656,
    type: "keyup",
    data: { key: "softDrop", subframe: 0.10000000000000009 }
  },
  { frame: 658, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 659, type: "keyup", data: { key: "rotateCCW", subframe: 0.6 } },
  { frame: 659, type: "keydown", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 660, type: "keydown", data: { key: "hold", subframe: 0.2 } },
  { frame: 663, type: "keydown", data: { key: "rotateCCW", subframe: 0.5 } },
  { frame: 665, type: "keyup", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 666, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 668, type: "keyup", data: { key: "moveLeft", subframe: 0 } },
  { frame: 669, type: "keydown", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 669, type: "keyup", data: { key: "rotateCCW", subframe: 0.8 } },
  { frame: 671, type: "keydown", data: { key: "hold", subframe: 0.7 } },
  { frame: 671, type: "keydown", data: { key: "moveRight", subframe: 0.7 } },
  { frame: 676, type: "keyup", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 678, type: "keyup", data: { key: "hold", subframe: 0.5 } },
  { frame: 680, type: "keyup", data: { key: "moveRight", subframe: 0 } },
  { frame: 680, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 683, type: "keydown", data: { key: "rotateCCW", subframe: 0.7 } },
  { frame: 683, type: "keydown", data: { key: "moveLeft", subframe: 0.7 } },
  { frame: 686, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 690, type: "keyup", data: { key: "rotateCCW", subframe: 0.5 } },
  { frame: 690, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 691, type: "keyup", data: { key: "moveLeft", subframe: 0.1 } },
  { frame: 695, type: "keydown", data: { key: "moveRight", subframe: 0.1 } },
  { frame: 700, type: "keyup", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 703, type: "keydown", data: { key: "moveLeft", subframe: 0.2 } },
  { frame: 707, type: "keyup", data: { key: "moveRight", subframe: 0 } },
  { frame: 707, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 707, type: "keyup", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 710, type: "keydown", data: { key: "hold", subframe: 0.3 } },
  { frame: 712, type: "keydown", data: { key: "moveLeft", subframe: 0.1 } },
  { frame: 715, type: "keyup", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 715, type: "keydown", data: { key: "rotateCW", subframe: 0.1 } },
  { frame: 716, type: "keyup", data: { key: "hold", subframe: 0.2 } },
  { frame: 718, type: "keyup", data: { key: "rotateCW", subframe: 0.4 } },
  { frame: 719, type: "keyup", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 720, type: "keydown", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 724, type: "keydown", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 724, type: "keydown", data: { key: "moveLeft", subframe: 0.8 } },
  { frame: 725, type: "keyup", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 727, type: "keyup", data: { key: "moveLeft", subframe: 0.8 } },
  { frame: 729, type: "keydown", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 730, type: "keyup", data: { key: "rotateCCW", subframe: 0.4 } },
  { frame: 734, type: "keydown", data: { key: "rotate180", subframe: 0.2 } },
  { frame: 734, type: "keydown", data: { key: "moveRight", subframe: 0.2 } },
  { frame: 737, type: "keyup", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 743, type: "keyup", data: { key: "rotate180", subframe: 0.2 } },
  { frame: 743, type: "keyup", data: { key: "moveRight", subframe: 0.2 } },
  { frame: 743, type: "keydown", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 745, type: "keydown", data: { key: "hold", subframe: 0.4 } },
  { frame: 748, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 748, type: "keydown", data: { key: "rotateCW", subframe: 0 } },
  { frame: 748, type: "keydown", data: { key: "moveRight", subframe: 0.1 } },
  { frame: 750, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 750, type: "keyup", data: { key: "rotateCW", subframe: 0 } },
  { frame: 750, type: "keyup", data: { key: "moveRight", subframe: 0 } },
  { frame: 752, type: "keydown", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 754, type: "keydown", data: { key: "hold", subframe: 0.5 } },
  { frame: 756, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 760, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 760, type: "keydown", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 764, type: "keydown", data: { key: "moveRight", subframe: 0.3 } },
  { frame: 769, type: "keyup", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 772, type: "keyup", data: { key: "moveRight", subframe: 0.6 } },
  { frame: 774, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 777, type: "keydown", data: { key: "rotateCW", subframe: 0.6 } },
  { frame: 777, type: "keydown", data: { key: "moveRight", subframe: 0.6 } },
  { frame: 779, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 779, type: "keyup", data: { key: "rotateCW", subframe: 0.5 } },
  { frame: 779, type: "keyup", data: { key: "moveRight", subframe: 0.5 } },
  { frame: 783, type: "keydown", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 785, type: "keydown", data: { key: "rotateCW", subframe: 0.7 } },
  { frame: 787, type: "keyup", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 789, type: "keyup", data: { key: "rotateCW", subframe: 0.5 } },
  { frame: 792, type: "keydown", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 795, type: "keydown", data: { key: "rotateCCW", subframe: 0.9 } },
  { frame: 797, type: "keydown", data: { key: "moveLeft", subframe: 0 } },
  { frame: 799, type: "keyup", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 800, type: "keyup", data: { key: "moveLeft", subframe: 0.3 } },
  { frame: 802, type: "keyup", data: { key: "rotateCCW", subframe: 0.1 } },
  { frame: 802, type: "keydown", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 806, type: "keydown", data: { key: "hold", subframe: 0 } },
  { frame: 808, type: "keydown", data: { key: "rotateCCW", subframe: 0 } },
  { frame: 808, type: "keydown", data: { key: "moveLeft", subframe: 0 } },
  { frame: 810, type: "keyup", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 810, type: "keyup", data: { key: "hold", subframe: 0.5 } },
  { frame: 810, type: "keyup", data: { key: "moveLeft", subframe: 0.5 } },
  { frame: 812, type: "keyup", data: { key: "rotateCCW", subframe: 0.9 } },
  { frame: 812, type: "keydown", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 816, type: "keydown", data: { key: "hold", subframe: 0 } },
  { frame: 816, type: "keydown", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 818, type: "keydown", data: { key: "rotateCCW", subframe: 0.7 } },
  { frame: 822, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 822, type: "keyup", data: { key: "hold", subframe: 0.5 } },
  { frame: 825, type: "keyup", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 826, type: "keyup", data: { key: "rotateCCW", subframe: 0.2 } },
  { frame: 827, type: "keydown", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 830, type: "keydown", data: { key: "moveRight", subframe: 0.5 } },
  { frame: 835, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 840, type: "keydown", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 844, type: "keydown", data: { key: "rotate180", subframe: 0.4 } },
  { frame: 847, type: "keyup", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 851, type: "keyup", data: { key: "rotate180", subframe: 0.5 } },
  { frame: 852, type: "keyup", data: { key: "moveRight", subframe: 0.6 } },
  { frame: 854, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 857, type: "keydown", data: { key: "hold", subframe: 0.7 } },
  { frame: 859, type: "keyup", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 860, type: "keyup", data: { key: "hold", subframe: 0.2 } },
  { frame: 860, type: "keydown", data: { key: "rotateCW", subframe: 0.2 } },
  { frame: 864, type: "keyup", data: { key: "rotateCW", subframe: 0.5 } },
  { frame: 864, type: "keydown", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 866, type: "keydown", data: { key: "hold", subframe: 0.4 } },
  { frame: 869, type: "keyup", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 869, type: "keydown", data: { key: "rotateCW", subframe: 0.8 } },
  { frame: 872, type: "keyup", data: { key: "hold", subframe: 0.3 } },
  { frame: 872, type: "keyup", data: { key: "rotateCW", subframe: 0.3 } },
  { frame: 873, type: "keydown", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 877, type: "keydown", data: { key: "hold", subframe: 0.1 } },
  { frame: 878, type: "keydown", data: { key: "moveLeft", subframe: 0.3 } },
  { frame: 879, type: "keydown", data: { key: "rotateCCW", subframe: 0.6 } },
  { frame: 882, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 883, type: "keyup", data: { key: "hold", subframe: 0.1 } },
  { frame: 883, type: "keyup", data: { key: "moveLeft", subframe: 0.2 } },
  { frame: 885, type: "keyup", data: { key: "rotateCCW", subframe: 0.4 } },
  { frame: 896, type: "keydown", data: { key: "moveLeft", subframe: 0.1 } },
  { frame: 904, type: "keyup", data: { key: "moveLeft", subframe: 0.8 } },
  { frame: 907, type: "keydown", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 910, type: "keydown", data: { key: "hold", subframe: 0 } },
  { frame: 910, type: "keydown", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 911, type: "keydown", data: { key: "rotateCCW", subframe: 0.3 } },
  { frame: 914, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 914, type: "keyup", data: { key: "moveLeft", subframe: 0 } },
  { frame: 914, type: "keyup", data: { key: "hold", subframe: 0.4 } },
  { frame: 917, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 917, type: "keyup", data: { key: "rotateCCW", subframe: 0.8 } },
  { frame: 920, type: "keydown", data: { key: "hold", subframe: 0.7 } },
  { frame: 920, type: "keydown", data: { key: "moveLeft", subframe: 0.7 } },
  { frame: 925, type: "keydown", data: { key: "rotate180", subframe: 0.1 } },
  { frame: 927, type: "keyup", data: { key: "hold", subframe: 0.7 } },
  { frame: 928, type: "keyup", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 929, type: "keydown", data: { key: "moveRight", subframe: 0.5 } },
  { frame: 932, type: "keyup", data: { key: "moveLeft", subframe: 0.5 } },
  { frame: 934, type: "keyup", data: { key: "rotate180", subframe: 0 } },
  { frame: 934, type: "keyup", data: { key: "moveRight", subframe: 0.1 } },
  { frame: 934, type: "keydown", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 935, type: "keydown", data: { key: "hold", subframe: 0.8 } },
  { frame: 937, type: "keydown", data: { key: "moveLeft", subframe: 0.5 } },
  { frame: 940, type: "keyup", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 942, type: "keyup", data: { key: "hold", subframe: 0.6 } },
  { frame: 946, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 947, type: "keydown", data: { key: "rotateCCW", subframe: 0.8 } },
  { frame: 950, type: "keyup", data: { key: "moveLeft", subframe: 0.3 } },
  { frame: 950, type: "keyup", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 954, type: "keyup", data: { key: "rotateCCW", subframe: 0.7 } },
  { frame: 954, type: "keydown", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 955, type: "keydown", data: { key: "moveRight", subframe: 0.9 } },
  { frame: 961, type: "keyup", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 964, type: "keyup", data: { key: "moveRight", subframe: 0.8 } },
  { frame: 966, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 969, type: "keydown", data: { key: "rotateCCW", subframe: 0.2 } },
  { frame: 969, type: "keydown", data: { key: "moveLeft", subframe: 0.2 } },
  { frame: 970, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 972, type: "keyup", data: { key: "moveLeft", subframe: 0.2 } },
  { frame: 974, type: "keyup", data: { key: "rotateCCW", subframe: 0.4 } },
  { frame: 974, type: "keydown", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 977, type: "keydown", data: { key: "rotateCW", subframe: 0.6 } },
  { frame: 977, type: "keydown", data: { key: "moveRight", subframe: 0.6 } },
  { frame: 980, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 980, type: "keyup", data: { key: "rotateCW", subframe: 0 } },
  { frame: 980, type: "keyup", data: { key: "moveRight", subframe: 0 } },
  { frame: 983, type: "keydown", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 985, type: "keydown", data: { key: "moveRight", subframe: 0.2 } },
  { frame: 988, type: "keyup", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 993, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 995, type: "keydown", data: { key: "hold", subframe: 0.6 } },
  { frame: 997, type: "keydown", data: { key: "rotate180", subframe: 0 } },
  { frame: 997, type: "keyup", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 998, type: "keyup", data: { key: "hold", subframe: 0.7 } },
  { frame: 1003, type: "keyup", data: { key: "rotate180", subframe: 0.8 } },
  { frame: 1003, type: "keydown", data: { key: "hardDrop", subframe: 0.8 } },
  { frame: 1005, type: "keydown", data: { key: "hold", subframe: 0.6 } },
  { frame: 1008, type: "keyup", data: { key: "hardDrop", subframe: 0.2 } },
  { frame: 1010, type: "keyup", data: { key: "hold", subframe: 0.6 } },
  { frame: 1012, type: "keydown", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 1014, type: "keydown", data: { key: "hold", subframe: 0.6 } },
  { frame: 1019, type: "keyup", data: { key: "hardDrop", subframe: 0 } },
  { frame: 1019, type: "keydown", data: { key: "moveLeft", subframe: 0 } },
  { frame: 1022, type: "keyup", data: { key: "moveRight", subframe: 0.6 } },
  { frame: 1022, type: "keyup", data: { key: "hold", subframe: 0.6 } },
  { frame: 1022, type: "keyup", data: { key: "moveLeft", subframe: 0.6 } },
  { frame: 1025, type: "keydown", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 1028, type: "keydown", data: { key: "rotateCCW", subframe: 0.3 } },
  { frame: 1030, type: "keydown", data: { key: "moveLeft", subframe: 0.5 } },
  { frame: 1031, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 1034, type: "keyup", data: { key: "moveLeft", subframe: 0 } },
  { frame: 1035, type: "keyup", data: { key: "rotateCCW", subframe: 0.6 } },
  { frame: 1035, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 1037, type: "keydown", data: { key: "hold", subframe: 0.9 } },
  { frame: 1039, type: "keydown", data: { key: "moveLeft", subframe: 0.8 } },
  { frame: 1042, type: "keydown", data: { key: "rotateCW", subframe: 0.3 } },
  { frame: 1043, type: "keyup", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 1046, type: "keyup", data: { key: "hold", subframe: 0 } },
  { frame: 1049, type: "keyup", data: { key: "moveLeft", subframe: 0.4 } },
  { frame: 1049, type: "keyup", data: { key: "rotateCW", subframe: 0.4 } },
  { frame: 1050, type: "keydown", data: { key: "hardDrop", subframe: 0.7 } },
  { frame: 1053, type: "keydown", data: { key: "hold", subframe: 0.5 } },
  { frame: 1056, type: "keydown", data: { key: "moveRight", subframe: 0.3 } },
  { frame: 1057, type: "keydown", data: { key: "rotateCW", subframe: 0 } },
  { frame: 1058, type: "keyup", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 1060, type: "keyup", data: { key: "moveRight", subframe: 0.2 } },
  { frame: 1060, type: "keyup", data: { key: "rotateCW", subframe: 0.3 } },
  { frame: 1061, type: "keyup", data: { key: "hold", subframe: 0.2 } },
  { frame: 1063, type: "keydown", data: { key: "hardDrop", subframe: 0.9 } },
  { frame: 1067, type: "keydown", data: { key: "moveRight", subframe: 0.4 } },
  { frame: 1068, type: "keydown", data: { key: "rotate180", subframe: 0 } },
  { frame: 1072, type: "keyup", data: { key: "hardDrop", subframe: 0.6 } },
  { frame: 1076, type: "keyup", data: { key: "rotate180", subframe: 0.6 } },
  { frame: 1078, type: "keyup", data: { key: "moveRight", subframe: 0.9 } },
  { frame: 1081, type: "keydown", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 1084, type: "keydown", data: { key: "moveRight", subframe: 0 } },
  { frame: 1087, type: "keyup", data: { key: "moveRight", subframe: 0 } },
  { frame: 1088, type: "keyup", data: { key: "hardDrop", subframe: 0.1 } },
  { frame: 1093, type: "keydown", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 1098, type: "keyup", data: { key: "hardDrop", subframe: 0.4 } },
  { frame: 1098, type: "keydown", data: { key: "moveRight", subframe: 0.4 } },
  { frame: 1100, type: "keyup", data: { key: "moveRight", subframe: 0.8 } },
  { frame: 1109, type: "keydown", data: { key: "hardDrop", subframe: 0.5 } },
  { frame: 1114, type: "keydown", data: { key: "rotate180", subframe: 0.1 } },
  { frame: 1114, type: "keydown", data: { key: "moveRight", subframe: 0.8 } },
  { frame: 1117, type: "keyup", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 1118, type: "keyup", data: { key: "moveRight", subframe: 0 } },
  { frame: 1120, type: "keyup", data: { key: "rotate180", subframe: 0 } },
  { frame: 1125, type: "keydown", data: { key: "hardDrop", subframe: 0 } },
  { frame: 1128, type: "keydown", data: { key: "moveRight", subframe: 0.1 } },
  { frame: 1129, type: "keyup", data: { key: "hardDrop", subframe: 0.3 } },
  { frame: 1129, type: "keydown", data: { key: "rotateCW", subframe: 0.4 } },
  { frame: 1129, type: "keyup", data: { key: "rotateCW", subframe: 0.9 } },
  { frame: 1131, type: "keyup", data: { key: "moveRight", subframe: 0.6 } },
  { frame: 1135, type: "keydown", data: { key: "hardDrop", subframe: 0.6 } }
];
